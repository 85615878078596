<template>
  <UCard
    :style="{
      '--mouse-x': mouseX + 'px',
      '--mouse-y': mouseY + 'px',
    }"
    class="landing-card"
    @mousemove="onMousemove"
  >
    <div class="relative z-10">
      <slot v-if="$slots.default" />
    </div>
    <div class="stars">
      <div v-for="i in 5" :key="i" class="star" :style="starPositions[i-1]"></div>
    </div>
  </UCard>
</template>

<script setup lang="ts">
const mouseX = ref(0);
const mouseY = ref(0);

const starPositions = ref(Array.from({length: 5}, () => ({
  top: Math.random() * 100 + '%',
  left: Math.random() * 100 + '%',
  animationDelay: Math.random() * 2 + 's'
})));

function onMousemove(e: MouseEvent) {
  const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
  mouseX.value = e.clientX - rect.left;
  mouseY.value = e.clientY - rect.top;
}
</script>

<style scoped>
.landing-card {
  position: relative;
  background-color: var(--color-white);
  transition: background-color 0.3s ease;
}

.dark .landing-card {
  background-color: var(--color-gray-900);
}

.landing-card::before {
  content: "";
  position: absolute;
  inset: 0;
  background: radial-gradient(
    circle 200px at var(--mouse-x) var(--mouse-y),
    rgb(251 100 182 / 7%),
    transparent
  );
  pointer-events: none;
}

.stars {
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  overflow: hidden;
  pointer-events: none;
}

.landing-card:hover .stars {
  opacity: 1;
}

.star {
  width: 8px;
  height: 8px;
  background-color: #fb64b6;
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  position: absolute;
  animation: twinkle 1.5s infinite;
}

@keyframes twinkle {
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.3;
    transform: scale(0.8);
  }
}
</style>
